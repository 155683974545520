import { createRouter, createWebHistory } from 'vue-router'
import IndexSeven from '../pages/IndexSeven'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexSeven
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
